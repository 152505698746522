import React from "react";
import styles from "../../style";
import { socialMedia } from "../../constants";
import { logo } from "../../assets";
import { useTranslation } from "react-i18next";

function Footer() {
  const [t, i18n0] = useTranslation();
  return (
    <section className={`${styles.flexCenter} ${styles.paddingY} flex-col`}>
      <div className={`${styles.flexCenter} md:flex-row flex-col mb-8 w-full`}>
        <div className="flex flex-col justify-center">
          <img
            src={logo}
            alt="logo"
            className="w-[200px] h-[200px] max-w-[310px] object-contain m-auto"
          />
          <p className={`${styles.paragraph} mt-4 max-w-[310px] text-center`}>
            {t("logo")}
          </p>
        </div>
      </div>

      <div className="w-full flex justify-between items-center md:flex-row flex-col pt-6 border-t-[1px] border-t-[#3R3E45]">
        <p className="font-poppins font-normal text-center text-[18px] leading-[27px] text-white">
          {t("Copyright © 2024 Wizard Tech Repairs. All Rights Reserved.")}
        </p>
        <div className="flex flex-row md:mt-0 mt-6">
          {socialMedia.map((social, index) => (
            <img
              key={social.id}
              src={social.icon}
              alt={social.id}
              className={`w-[21px] h-[21px] object-contain cursor-pointer ${
                index !== socialMedia.length - 1 ? "mr-6" : "mr-0"
              }`}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Footer;
