import React, { useState } from "react";
import styles, { layout } from "../../style";
import { useTranslation } from "react-i18next";
import axios from "axios";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import FailureToast from "../FailureToast/FailureToast";

function ContactBuyBack() {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [price, setPrice] = useState("");
  const [device, setDevice] = useState("");
  const [condition, setCondition] = useState("");
  const [about, setAbout] = useState("");
  // const [image, setImage] = useState(null);

  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();
    console.log("clicked!");
    setLoading(true);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/buyback",
        {
          firstName,
          lastName,
          phone,
          device,
          condition,
          price,
          about,
          // image,
        }
      );

      if (response.status === 200) {
        console.log("Email sent successfully");
        setEmailSent(true);
        setLoading(false);

        setFirstName("");
        setLastName("");
        setDevice("");
        setCondition("");
        setPhone("");
        setPrice("");
        setAbout("");
        // setImage(null);
      } else {
        console.error("Failed to send email");
        setFailed(true);
      }
    } catch (error) {
      console.error("Error sending email: ", error);
      setFailed(true);
    }
    setLoading(false);
  };

  return (
    <section
      id="buyback"
      className={`${layout.section} flex items-center justify-center min-h-screen `}>
      <div className="w-full">
        <div
          className={`${styles.flexCenter} flex-col xl:px-0 sm:px-16 px-6 xl:py-10 sm:py-10 py-3`}>
          <h1
            className={`flex-1 font-poppins font-semibold ss:text-[60px] text-[35px] text-center text-white text-gradient ss:leading-[100px] leading-[75px]`}>
            {t("Upgrade for a better product")}
          </h1>
          {/* <p className="font-poppins font-normal text-dimWhite text-[16px] leading-[24px] mb-1">
            {t("RepairCost")}
          </p> */}
        </div>
        <form onSubmit={sendEmail}>
          <div className="space-y-12">
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 pb-12">
              <div className="sm:col-span-3">
                <label
                  htmlFor="device"
                  className="block text-sm font-medium leading-6 text-white">
                  {t("Your device")}
                </label>
                <div className="mt-2">
                  <input
                    required
                    type="text"
                    name="device"
                    id="device"
                    value={device}
                    onChange={(e) => {
                      setDevice(e.target.value);
                    }}
                    autoComplete="device"
                    className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="condition"
                  className="block text-sm font-medium leading-6 text-white">
                  {t("Condition")}
                </label>
                <div className="mt-2">
                  <input
                    required
                    id="condition"
                    name="condition"
                    autoComplete="condition"
                    value={condition}
                    onChange={(e) => {
                      setCondition(e.target.value);
                    }}
                    className="h-9 block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-white">
                  {t("First name")}
                </label>
                <div className="mt-2">
                  <input
                    required
                    type="text"
                    name="first-name"
                    id="first-name"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium leading-6 text-white">
                  {t("Last name")}
                </label>
                <div className="mt-2">
                  <input
                    required
                    type="text"
                    name="last-name"
                    id="last-name"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium leading-6 text-white">
                  {t("Phone number")}
                </label>
                <div className="mt-2">
                  <input
                    required
                    id="phone"
                    name="phone"
                    type="text"
                    pattern="[0-9]{3} [0-9]{3}-[0-9]{4}"
                    placeholder="(514) 514-5144"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    autoComplete="phone"
                    className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="price"
                  className="block text-sm font-medium leading-6 text-white">
                  {t("How much do you want in exchange?")}
                </label>
                <div className="mt-2">
                  <input
                    required
                    id="price"
                    name="price"
                    type="number"
                    pattern="[0-9]{10}"
                    value={price}
                    onChange={(e) => {
                      setPrice(e.target.value);
                    }}
                    autoComplete="price"
                    className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-full">
            <label
              htmlFor="about"
              className="block text-sm font-medium leading-6 text-white">
              {t("About Device")}
            </label>
            <div className="mt-2">
              <textarea
                required
                id="about"
                name="about"
                rows={3}
                value={about}
                onChange={(e) => {
                  setAbout(e.target.value);
                }}
                className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <p className="mt-3 text-sm leading-6 text-dimWhite">
              {t("Description of your device.")}
            </p>
          </div>

          <div className="mt-6 flex flex-col items-center justify-center gap-x-6">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <button
                type="submit"
                onSubmit={sendEmail}
                disabled={emailSent}
                className={`py-4 px-6 bg-blue-gradient font-poppins font-medium text-[18px] text-primary outline-none ${styles} rounded-[10px]`}>
                {emailSent ? t("SentBuyback") : t("Send")}
              </button>
            )}
            {failed ? (
              <FailureToast text={t("ErrorSending")} setFailed={setFailed} />
            ) : (
              <></>
            )}
          </div>
        </form>
      </div>
    </section>
  );
}

export default ContactBuyBack;
