import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function Button({ styles }) {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  return (
    <button
      type="button"
      onClick={() => {
        navigate("/repair");
      }}
      className={`py-4 px-6 bg-blue-gradient font-poppins font-medium text-[18px] text-primary outline-none ${styles} rounded-[10px]`}>
      {t("Contact Us")}
    </button>
  );
}

export default Button;
