import React from "react";
import styles from "../../style";
import "./cardwelcome.css";

function CardWelcome({ content, cat, img }) {
  return (
    <div className="flip-card md:w-[340px] md:h-[340px] w-[240px] h-[240px]  max-w-[370px] cursor-pointer my-5">
      <div className="flip-card-inner border-2 max-w-[370px] border-[#9dedf0] rounded-[20px]">
        <div
          className={`flip-card-front flex ${styles.flexCenter} justify-between flex-col px-10 py-12 rounded-[20px] max-w-[370px] md:mr-10 sm:mr-5 mr-0 my-5  md:w-[340px] md:h-[340px] w-[240px] h-[240px]`}>
          <img
            src={img}
            alt="quotes"
            className="md:w-[150px] md:h-[150px] w-[105px] h-[105px] object-contain"
          />

          <div className="flex flex-row">
            <div className="flex flex-col ml-4">
              <h4 className="font-poppins font-medium text-center text-white md:text-[35px] text-[25px] leading-[45px] mr-2">
                {cat}
              </h4>
            </div>
          </div>
        </div>
        <div
          className={`flip-card-back flex ${styles.flexCenter} justify-center flex-col px-10 py-12 rounded-[20px] text-white`}>
          <img
            src={img}
            alt="quotes"
            className="md:w-[50px] md:h-[50px] w-[40px] h-[40px] object-contain absolute md:top-[2rem] md:left-[2rem] top-[1rem] left-[1rem]"
          />
          <ul className="list-none">
            {Array.isArray(content) &&
              content.map((cont, index) => (
                <li key={index}>
                  <p className="font-poppins font-normal md:text-[25px] text-[18px] md:leading-[32px] leading-[22px] text-white my-10">
                    {cont}
                  </p>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CardWelcome;
