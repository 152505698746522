import React from "react";
import { features } from "../../constants";
import styles, { layout } from "../../style";
import Button from "../Button/Button.jsx";
import { useTranslation } from "react-i18next";

const FeatureCard = ({ icon, title, content, index }) => (
  <div
    className={`flex flex-row p-6 rounded-[20px] w-full ${
      index !== features.length - 1 ? "mb-6" : "mb-0"
    } feature-card `}>
    <div
      className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter} bg-dimBlue`}>
      <img src={icon} alt="icon" className="w-[50%] h-[50%] object-contain" />
    </div>
    <div className="flex-1 flex flex-col ml-3">
      <h4 className="font-poppins font-semibold text-white text-[18px] leading-[23px] mb-1">
        {" "}
        {title}
      </h4>
      <p className="font-poppins font-normal text-dimWhite text-[16px] leading-[24px] mb-1">
        {" "}
        {content}
      </p>
    </div>
  </div>
);

function Services() {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation();

  return (
    <section id="services" className={layout.section}>
      <div className={layout.sectionInfo}>
        <h2 className={styles.heading2}>
          {t("wtr-hero1")} <br className="sm:block hidden" /> {t("wtr-hero2")}
        </h2>
        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
          {t(
            "With the right credit card, you can improve your financial life by building credit, earning rewards and saving money. But with hundreds of credit cards on the market."
          )}
        </p>
        <Button styles="mt-10" />
      </div>
      <div className={`${layout.sectionImg} flex-col`}>
        {features.map((feature, index) => (
          <FeatureCard
            key={feature.id}
            icon={feature.icon}
            title={t(feature.title)}
            content={t(feature.content)}
            index={index}
          />
        ))}
      </div>
    </section>
  );
}

export default Services;
