export default function Iqw() {
  const fullUrl = window.location.href;
  let src =
    "https://widget.repairlift.biz/widget/1cr3zOTM21sGLd6RrMcR?BrandName=WizardTech&data-thanks-url=https%3A%2F%2Fwizardtechrepair.repairlift.site%2Finstant-quote-ga-thank-you-montreal";

  if (fullUrl) {
    src = src + "&from=" + fullUrl;
  }

  window.addEventListener(
    "message",
    function (e) {
      const iqw = window.document.getElementById("widget_1cr3zOTM21sGLd6RrMcR");

      if (typeof e.data == "string" && e.data.split("|")[1]) {
        iqw.style.height = e.data.split("|")[1];
      }

      // iqw.scrollIntoView({ behavior: "smooth" });

      // if (e.data.scrollTop !== undefined) {
      //   window.scrollTo(0, e.data.scrollTop);
      // }
    },
    false
  );

  return (
    <iframe
      id="widget_1cr3zOTM21sGLd6RrMcR"
      src={src}
      width="100%"
      frameBorder="no"
      scrolling="no"
      allow="geolocation"
      data-thanks-url="https://wizardtechrepair.repairlift.site/instant-quote-ga-thank-you-montreal"></iframe>
  );
}
