import React from "react";
import styles from "../../style";
import "./videobg.css";
import { video } from "../../assets";

function VideoBg() {
  return (
    <section id="videoBg" className={`min-h-screen flex flex-col w-[100%]`}>
      <div
        className={`mb-20 md:mb-auto ${styles.flexCenter} w-[100%] h-[100vh] fix-mb-small-screen`}>
        <video
          src={video}
          autoPlay
          loop
          muted
          className="object-cover w-[100%] h-[100vh]"></video>
        <div className="absolute h-[100vh] gradient-overlay gradient-overlay-top"></div>
      </div>
    </section>
  );
}

export default VideoBg;
