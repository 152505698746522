import React from "react";
import styles from "../style";

import { Navbar, Hero, Services, Location, Footer } from "../components";
import Welcome from "../components/Welcome/Welcome";
import VideoBg from "../components/VideoBg/VideoBg";

function Main() {
  return (
    <div className="bg-primary w-full overflow-hiddenr">
      <div
        className={`${styles.paddingX} ${styles.flexCenter} absolute left-0 right-0 z-20`}>
        <div className={`${styles.boxWidth}`}>
          <Navbar />
        </div>
      </div>

      <div className={`${styles.flexCenter}`}>
        <VideoBg />
      </div>
      <div className={`${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <Welcome />
        </div>
      </div>

      <div className={`bg-primary ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <Hero />
        </div>
      </div>

      <div className={`bg-primary ${styles.paddingX} ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <Services />
          {/* <Contact /> */}
          <Location />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Main;
