import {
  //   people01,
  //   people02,
  //   people03,
  facebook,
  instagram,
  twitter,
  //   airbnb,
  //   binance,
  //   coinbase,
  //   dropbox,
  send,
  shield,
  star,
} from "../assets";

export const navLinks = [
  {
    id: "home",
    title: "Home",
  },
  {
    id: "services",
    title: "Services",
  },
  {
    id: "contact-us",
    title: "Contact",
  },
  {
    id: "location",
    title: "Location",
  },
];

export const devices = [
  {
    id: "device-1",
    title: "Android Phone",
    modeles: [
      { id: "model-1", title: "Samsung" },
      { id: "model-2", title: "LG" },
      { id: "model-3", title: "Google Pixel" },
      { id: "model-4", title: "Motorola" },
      { id: "model-5", title: "OnePlus" },
      { id: "model-6", title: "HTC" },
      { id: "model-7", title: "Huawei" },
    ],
  },
  {
    id: "device-2",
    title: "iPhone",
    modeles: [
      { id: "model-1", title: "Apple iPhone 15 Pro Max" },
      { id: "model-2", title: "Apple iPhone 15 Pro" },
      { id: "model-3", title: "Apple iPhone 15 Plus" },
      { id: "model-4", title: "Apple iPhone 15" },
      { id: "model-5", title: "Apple iPhone 14 Pro Max" },
      { id: "model-6", title: "Apple iPhone 14 Pro" },
      { id: "model-7", title: "Apple iPhone 14 Plus" },
      { id: "model-8", title: "Apple iPhone 14" },
      { id: "model-9", title: "Apple iPhone SE (2022)" },
      { id: "model-10", title: "Apple iPhone 13 Pro Max" },
      { id: "model-11", title: "Apple iPhone 13 Pro" },
      { id: "model-12", title: "Apple iPhone 13 Mini" },
      { id: "model-13", title: "Apple iPhone 13" },
      { id: "model-14", title: "Apple iPhone 12 Pro Max" },
      { id: "model-15", title: "Apple iPhone 12 Pro" },
      { id: "model-16", title: "Apple iPhone 12 mini" },
      { id: "model-17", title: "Apple iPhone 12" },
      { id: "model-18", title: "Apple iPhone SE (2020)" },
      { id: "model-19", title: "Apple iPhone 11 Pro Max" },
      { id: "model-20", title: "Apple iPhone 11 Pro" },
      { id: "model-21", title: "Apple iPhone 11" },
      { id: "model-22", title: "Apple iPhone XR" },
      { id: "model-23", title: "Apple iPhone XS MAX" },
      { id: "model-24", title: "Apple iPhone XS" },
      { id: "model-25", title: "Apple iPhone X" },
      { id: "model-26", title: "Apple iPhone 8 Plus" },
      { id: "model-27", title: "Apple iPhone 8" },
      { id: "model-28", title: "Apple iPhone 7 Plus" },
      { id: "model-29", title: "Apple iPhone 7" },
      { id: "model-30", title: "Apple iPhone 6s Plus" },
      { id: "model-31", title: "Apple iPhone 6s" },
      { id: "model-32", title: "Apple iPhone 6 Plus" },
      { id: "model-33", title: "Apple iPhone 6" },
    ],
  },
  {
    id: "device-3",
    title: "Tablet",
    modeles: [
      { id: "model-1", title: "Samsung Tablet" },
      { id: "model-2", title: "Apple iPad Pro 12.9 (6th Gen)" },
      { id: "model-3", title: "Apple iPad Prod 11 (4th Gen)" },
      { id: "model-4", title: "Apple iPad 10th Gen" },
      { id: "model-5", title: "Apple iPad Air (5th Gen)" },
      { id: "model-6", title: "Apple iPad Pro 12.9 (3rd Gen)" },
      { id: "model-7", title: "Apple iPad Mini 6" },
      { id: "model-8", title: "Apple iPad 9th Gen" },
      { id: "model-9", title: "Apple iPad Pro 12.9 (5th Gen)" },
      { id: "model-10", title: "Apple iPad Pro 11 (3rd Gen)" },
      { id: "model-11", title: "Apple iPad Air 4" },
      { id: "model-12", title: "Apple iPad 8th Gen" },
      { id: "model-13", title: "Apple iPad Pro 12.9 (4th Gen)" },
      { id: "model-14", title: "Apple iPad Pro 11 (2nd Gen)" },
      { id: "model-15", title: "Apple iPad 7th Gen" },
      { id: "model-16", title: "Apple iPad Air 3" },
      { id: "model-17", title: "Apple iPad Mini 5" },
      { id: "model-18", title: "Apple iPad Pro 11 (3nd Gen)" },
      { id: "model-19", title: "Apple iPad Pro 11 (1st Gen)" },
      { id: "model-20", title: "Apple iPad 6th Gen" },
      { id: "model-21", title: "Apple iPad Pro 12.9 (2nd Gen)" },
      { id: "model-22", title: "Apple iPad Pro 10.5" },
      { id: "model-23", title: "Apple iPad 9.7 5th Gen" },
      { id: "model-24", title: "Apple iPad Pro 9.7" },
      { id: "model-25", title: "Apple iPad Pro 12.9 (1st Gen)" },
      { id: "model-26", title: "Apple iPad Mini 4" },
      { id: "model-27", title: "Apple iPad Air 2" },
      { id: "model-28", title: "Apple iPad Mini 3" },
      { id: "model-29", title: "Apple iPad Air 1" },
      { id: "model-30", title: "Apple iPad Mini 2" },
      { id: "model-31", title: "Apple iPad Mini 1" },
      { id: "model-32", title: "Apple iPad 4" },
      { id: "model-33", title: "Apple iPad 3" },
      { id: "model-34", title: "Apple iPad 2" },
      { id: "model-35", title: "Apple iPad 1" },
    ],
  },
  {
    id: "device-4",
    title: "Computer",
    modeles: [
      { id: "model-1", title: "PC Laptop" },
      { id: "model-2", title: "PC Desktop" },
      { id: "model-3", title: "Chromebook" },
      { id: "model-4", title: "iMac" },
      { id: "model-5", title: "MacBook" },
    ],
  },
  {
    id: "device-5",
    title: "Game Console",
    modeles: [
      { id: "model-1", title: "Xbox" },
      { id: "model-2", title: "PlayStation" },
      { id: "model-3", title: "Nintedo" },
    ],
  },
  {
    id: "device-6",
    title: "Apple Watch",
    modeles: [
      { id: "model-1", title: "Apple Watch Series 7 45mm" },
      { id: "model-2", title: "Apple Watch Series 7 41mm" },
      { id: "model-3", title: "Apple Watch Series 6 44mm" },
      { id: "model-4", title: "Apple Watch Series 6 40mm" },
      { id: "model-5", title: "Apple Watch Series SE 44mm" },
      { id: "model-6", title: "Apple Watch Series SE 40mm" },
      { id: "model-7", title: "Apple Watch Series 5 44mm" },
      { id: "model-8", title: "Apple Watch Series 5 40mm" },
      { id: "model-9", title: "Apple Watch Series 4 44mm" },
      { id: "model-10", title: "Apple Watch Series 4 40mm" },
      { id: "model-11", title: "Apple Watch Series 3 42mm" },
      { id: "model-12", title: "Apple Watch Series 3 38mm" },
      { id: "model-13", title: "Apple Watch Series 2 42mm" },
      { id: "model-14", title: "Apple Watch Series 2 38mm" },
      { id: "model-15", title: "Apple Watch Series 1 42mm" },
      { id: "model-16", title: "Apple Watch Series 1 38mm" },
    ],
  },
];

export const problems = [
  {
    id: "Android Phone",
    probs_list: [
      {
        id: "problem-1",
        title: "Battery",
      },
      {
        id: "problem-2",
        title: "Speaker",
      },
      {
        id: "problem-3",
        title: "Front camera",
      },
      {
        id: "problem-4",
        title: "Back camera",
      },
      {
        id: "problem-5",
        title: "Diagnostic",
      },
      {
        id: "problem-6",
        title: "Data recovery",
      },
      {
        id: "problem-6",
        title: "Doesn't turn on",
      },
      {
        id: "problem-7",
        title: "Hard disk",
      },
      {
        id: "problem-8",
        title: "Overheated",
      },
      {
        id: "problem-9",
        title: "Password recovery",
      },
      {
        id: "problem-9",
        title: "Broken screen",
      },
      {
        id: "problem-9",
        title: "Back Glass",
      },
    ],
  },
  {
    id: "iPhone",
    probs_list: [
      {
        id: "problem-1",
        title: "Battery",
      },
      {
        id: "problem-2",
        title: "Speaker",
      },
      {
        id: "problem-3",
        title: "Front camera",
      },
      {
        id: "problem-4",
        title: "Back camera",
      },
      {
        id: "problem-5",
        title: "Diagnostic",
      },
      {
        id: "problem-6",
        title: "Data recovery",
      },
      {
        id: "problem-6",
        title: "Doesn't turn on",
      },
      {
        id: "problem-7",
        title: "Hard disk",
      },
      {
        id: "problem-8",
        title: "Overheated",
      },
      {
        id: "problem-9",
        title: "Password recovery",
      },
      {
        id: "problem-9",
        title: "Broken screen",
      },
      {
        id: "problem-9",
        title: "Back Glass",
      },
    ],
  },
  {
    id: "Computer",
    probs_list: [
      {
        id: "problem-1",
        title: "Battery",
      },
      {
        id: "problem-2",
        title: "Speaker",
      },
      {
        id: "problem-3",
        title: "Water damage",
      },
      {
        id: "problem-4",
        title: "Virus",
      },
      {
        id: "problem-5",
        title: "Diagnostic",
      },
      {
        id: "problem-6",
        title: "Data recovery",
      },
      {
        id: "problem-6",
        title: "Doesn't turn on",
      },
      {
        id: "problem-7",
        title: "Hard disk",
      },
      {
        id: "problem-8",
        title: "Overheated",
      },
      {
        id: "problem-9",
        title: "Password recovery",
      },
    ],
  },
  {
    id: "Tablet",
    probs_list: [
      {
        id: "problem-1",
        title: "Battery",
      },
      {
        id: "problem-2",
        title: "Speaker",
      },
      {
        id: "problem-3",
        title: "Water damage",
      },
      {
        id: "problem-4",
        title: "Front camera",
      },
      {
        id: "problem-5",
        title: "Back camera",
      },
      {
        id: "problem-6",
        title: "Broken screen",
      },
      {
        id: "problem-6",
        title: "Doesn't turn on",
      },
    ],
  },
  {
    id: "Game Console",
    probs_list: [
      {
        id: "problem-1",
        title: "Diagnostic",
      },
      {
        id: "problem-2",
        title: "Overheated",
      },
      {
        id: "problem-3",
        title: "Hard disk",
      },
      {
        id: "problem-3",
        title: "Storage and save",
      },
      {
        id: "problem-4",
        title: "Water damage",
      },
    ],
  },
  {
    id: "Apple Watch",
    probs_list: [
      {
        id: "problem-1",
        title: "Battery",
      },
      {
        id: "problem-2",
        title: "Charge port",
      },
      {
        id: "problem-3",
        title: "Speaker",
      },
      {
        id: "problem-4",
        title: "Water damage",
      },
      {
        id: "problem-5",
        title: "Doesn't turn on",
      },
    ],
  },
];

export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "Rewards",
    content:
      "The best credit cards offer some tantalizing combinations of promotions and prizes",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "100% Secured",
    content:
      "We take proactive steps make sure your information and transactions are secure.",
  },
  {
    id: "feature-3",
    icon: send,
    title: "Balance Transfer",
    content:
      "A balance transfer credit card can save you a lot of money in interest charges.",
  },
];
/*
export const feedback = [
  {
    id: "feedback-1",
    content:
      "Money is only a tool. It will take you wherever you wish, but it will not replace you as the driver.",
    name: "Herman Jensen",
    title: "Founder & Leader",
    img: people01,
  },
  {
    id: "feedback-2",
    content:
      "Money makes your life easier. If you're lucky to have it, you're lucky.",
    name: "Steve Mark",
    title: "Founder & Leader",
    img: people02,
  },
  {
    id: "feedback-3",
    content:
      "It is usually people in the money business, finance, and international trade that are really rich.",
    name: "Kenn Gallagher",
    title: "Founder & Leader",
    img: people03,
  },
];

export const stats = [
  {
    id: "stats-1",
    title: "User Active",
    value: "3800+",
  },
  {
    id: "stats-2",
    title: "Trusted by Company",
    value: "230+",
  },
  {
    id: "stats-3",
    title: "Transaction",
    value: "$230M+",
  },
];
*/

export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/",
  },
  {
    id: "social-media-3",
    icon: twitter,
    link: "https://www.twitter.com/",
  },
];
/*
export const clients = [
  {
    id: "client-1",
    logo: airbnb,
  },
  {
    id: "client-2",
    logo: binance,
  },
  {
    id: "client-3",
    logo: coinbase,
  },
  {
    id: "client-4",
    logo: dropbox,
  },
];
*/
