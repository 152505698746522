import React, { useEffect } from "react";
import { Exchange, Main, Repair } from "./pages";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);
  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/repair" element={<Repair />} />
        <Route path="/exchange" element={<Exchange />} />
      </Routes>
    </Router>
  );
}

export default App;
