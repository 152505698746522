import React from "react";
import styles from "../../style";
import { useTranslation } from "react-i18next";

function Location() {
  const [t, i18n] = useTranslation();
  return (
    <section
      id="location"
      className={`${styles.flexCenter} ${styles.paddingY} ${styles.boxWidth} flex-col`}>
      <h2 className={styles.heading2}>{t("Our Location")}</h2>
      <div className={`${styles.flexCenter} md:flex-row flex-col m-8 w-full`}>
        <div
          className="w-full relative border-2 border-gray-300 rounded-lg shadow-lg overflow-hidden"
          style={{ paddingBottom: "56.25%" }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2791.298069795818!2d-73.55514492338743!3d45.60465372405604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc91f43aec90e9b%3A0x9d9f40c578e9afa0!2sWizard%20Tech%20repairs!5e0!3m2!1sfr!2sdz!4v1716893546444!5m2!1sfr!2sdz"
            title="wtr_location"
            className="absolute top-0 left-0 w-full h-full border-0"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </section>
  );
}

export default Location;
