import React from "react";
import styles from "../../style";
import "./welcome.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CardWelcome from "../CardWelcome/CardWelcome";
import { inventory, repair, exchange } from "../../assets";

function Welcome() {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const handleCardRedirect = (path) => {
    navigate(path);
  };

  return (
    <section
      id="welcome"
      className={`min-h-screen flex flex-col relative top-[-40rem] mb-[-40rem]`}>
      <div
        className={`relative ${styles.flexCenter} flex-col xl:px-0 sm:px-16 px-6 xl:py-20 sm:py-16 py-6`}>
        <h1
          className={`flex-1 font-poppins font-semibold ss:text-[60px] text-[35px] text-center text-white text-gradient ss:leading-[100px] leading-[75px]`}>
          {`${t("Welcome")} ${t("To")} ${t("Wizard Tech")}`}
        </h1>
      </div>
      <div
        className={`md:mt-20 md:mb-auto ${styles.flexCenter} md:flex-row flex-col xl:px-0 sm:px-16 px-6`}>
        <div
          className={`md:py-0 py-6  ${styles.flexStart} xl:mx-10 sm:mx-16 mx-6`}
          onClick={() =>
            (window.location.href = "https://wizard-tech-mtl.myshopify.com/")
          }>
          <CardWelcome
            content={[t("computer"), t("phone"), t("tablette")]}
            cat={t("Inventory")}
            img={inventory}
          />
        </div>
        <div
          className={`md:py-0 py-6 ${styles.flexStart} xl:mx-10 sm:mx-16 mx-6`}
          onClick={() => handleCardRedirect("/repair")}>
          <CardWelcome
            content={[t("computer"), t("phone"), t("tablette")]}
            cat={t("Repair1")}
            img={repair}
          />
        </div>
        <div
          className={`md:py-0 py-6 ${styles.flexStart} xl:mx-10 sm:mx-16 mx-6`}
          onClick={() => handleCardRedirect("/exchange")}>
          <CardWelcome
            content={[t("Exchange"), t("Refurbishing"), t("Recycling")]}
            cat={t("Exchange1")}
            img={exchange}
          />
        </div>
      </div>
    </section>
  );
}

export default Welcome;
