import React from "react";
import styles from "../style";

import { Navbar, Footer, ContactBuyBack } from "../components";

function Exchange() {
  return (
    <div className="bg-primary w-full overflow-hidden">
      <div className={`${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <Navbar />
          <div
            className={`sm:hidden w-full bg-blue-gradient h-[2px] drop-shadow-md shadow-red-400`}></div>
          {/* <Welcome /> */}
        </div>
      </div>

      <div className={`bg-primary ${styles.paddingX} ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <ContactBuyBack />

          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Exchange;
