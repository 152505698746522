import React, { useState } from "react";
import { close, logo, menu } from "../../assets";
import { navLinks } from "../../constants";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "../../style";

function Navbar() {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const [lang, setLang] = useState("EN");
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <nav className="w-full flex py-6 justify-between items-center navbar">
        <div className="flex flex-row">
          <img
            src={logo}
            alt="wtr"
            onClick={() => {
              navigate("/");
            }}
            className="sm:w-[75px] sm:h-[75px] w-[120px] h-[120px] object-contain cursor-pointer"
          />
          <div
            className={`font-poppins font-semibold text-gradient-2 cursor-pointer text-[14px]
              text-white inline-block`}>
            <span className="block w-full">{t("REPAIR")}</span>
            <span className="block w-full">{t("MICRO SOLDERING")}</span>
            <span className="block w-full">{t("RETAIL")}</span>
            <span className="block w-full">{t("WHOLESALE")}</span>
          </div>
        </div>
        <ul className="list-none sm:flex hidden justify-end items-center flex-1">
          {navLinks.map((nav, index) => (
            <li
              key={nav.id}
              className={`font-poppins font-normal cursor-pointer text-[16px] ${
                index === navLinks.length - 1 ? "mr-10" : "mr-10"
              } text-white `}>
              {nav.id === "contact-us" ? (
                <a href={`/repair`}>{t(nav.title)}</a>
              ) : (
                <a href={`/#${nav.id}`}>{t(nav.title)}</a>
              )}
            </li>
          ))}
          <li
            key={"lang"}
            className={`font-poppins font-normal cursor-pointer text-[16px] text-white`}>
            <button
              onClick={() => {
                if (i18n.language === "en") {
                  i18n.changeLanguage("fr");
                  setLang("EN");
                } else {
                  i18n.changeLanguage("en");
                  setLang("FR");
                }
              }}>
              {lang}
            </button>
          </li>
        </ul>
        <div className="sm:hidden flex flex-1 justify-end items-center z-10">
          <img
            src={toggle ? close : menu}
            alt="menu"
            className="w-[28px] h-[28px] object-contain"
            onClick={() => setToggle((prev) => !prev)}
          />

          <div
            className={`${
              toggle ? "flex" : "hidden"
            } p-6 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}>
            <ul className="list-none flex flex-col justify-end items-center flex-1">
              {navLinks.map((nav, index) => (
                <li
                  key={nav.id}
                  className={`font-poppins font-normal cursor-pointer text-[16px] ${
                    index === navLinks.length - 1 ? "mb-4" : "mb-4"
                  } text-white `}>
                  <a href={`#${nav.id}`}>{nav.title}</a>
                </li>
              ))}
              <li
                key={"lang"}
                className={`font-poppins font-normal cursor-pointer text-[16px] text-white`}>
                <button
                  onClick={() => {
                    if (i18n.language === "en") {
                      i18n.changeLanguage("fr");
                      setLang("EN");
                    } else {
                      i18n.changeLanguage("en");
                      setLang("FR");
                    }
                  }}>
                  {lang}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div
        className={`sm:hidden w-full bg-blue-gradient h-[2px] drop-shadow-md shadow-white-400 -z-10`}></div>
    </>
  );
}

export default Navbar;
